import classes from "*.module.css";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useUserState } from "../../../contexts/UserContext";
import checklist from "../../../resources/Algemene checklist 2020.pdf";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 2,
    },
  })
);

const WelcomeText: React.FC = () => {
  const classes = useStyles();

  const user = useUserState();

  return (
    <div className={classes.root}>
      <Typography variant="h6" style={{ marginBottom: "10px" }}>
        Beste {user.user?.name ?? ""},
      </Typography>
      <Typography variant="body1">
        <p>
          Om het uitwisselen van digitale bestanden veiliger te maken hebben wij deze digitale omgeving gemaakt. U kunt
          hierop via een laptop, tablet of telefoon documenten of foto’s toevoegen. Op uw telefoon of tablet kunt u door
          hieronder te klikken ook een foto maken en die meteen met mij delen. <br />
          Dit is de eerste keer dat wij dit gebruiken. Mocht er iets niet werken zoals het zou moeten of u heeft vragen
          kunt u contact opnemen met Brigitte Dirksen op telefoonnummer 06-24256457 of via e-mail{" "}
          <a href="mailto:info@dirksenadvies.nl">info@dirksenadvies.nl</a>.
        </p>
        <p>
          <a href={checklist}>Hier</a> vindt u een standaard checklist voor de belastingaangifte Wilt u een
          gepersonaliseerde checklist ontvangen, dan vernemen wij dat graag telefonisch of per e-mail.
        </p>
      </Typography>
    </div>
  );
};

export { WelcomeText };
