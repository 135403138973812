import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FileDescription } from "../../../model";
import { deleteRemoteFile } from "../../../util/fileserver-client";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";

import pb from "pretty-bytes";
import { useUserState } from "../../../contexts/UserContext";
import { Config } from "../../../config";

const useStyles = makeStyles({
  table: {
    minWidth: "80%",
  },
  downloadLink: {
    color: "black",
    "&:hover": {
      color: "black",
      textDecoration: "none",
    },
    "&:visited": {
      color: "black",
      textDecoration: "none",
    },
    "&:active": {
      color: "black",
      textDecoration: "none",
    },
  },
});

const makeRow = (fileName: string, description: string, date: Date, size: number): FileDescription => ({
  fileName,
  description,
  date,
  size,
});

const df = new Intl.DateTimeFormat("nl");

interface FileListingProps {
  filesToShow: FileDescription[];
  allowDelete: boolean;
  onFileDeleted?: (fileName: string) => void;
  downloadPrefix: string;
}

const FileListing: React.FC<FileListingProps> = ({ filesToShow, allowDelete, onFileDeleted, downloadPrefix }) => {
  const classes = useStyles();
  const user = useUserState();

  const sortedFiles = filesToShow.sort((a, b) => {
    if (a.date.getTime() < b.date.getTime()) {
      return 0;
    } else {
      return 1;
    }
  });

  const deleteFile = (filename: string) => {
    deleteRemoteFile(user.user?.token ?? "", filename).then(() => {
      if (onFileDeleted) {
        onFileDeleted(filename);
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "70%" }}>Naam</TableCell>
            <TableCell style={{ width: "5%" }} align="right">
              Datum
            </TableCell>
            <TableCell style={{ width: "12%" }} align="right">
              Grootte
            </TableCell>
            <TableCell style={{ width: "13%" }} align="right">
              Acties
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFiles.map((row) => (
            <TableRow key={row.fileName} style={{ width: "10%" }}>
              <TableCell component="th" scope="row">
                <a
                  href={
                    `${Config.apiEndpoint}/${downloadPrefix}?fileName=` +
                    encodeURIComponent(row.fileName) +
                    `&xdatoken=${encodeURIComponent(user.user?.token ?? "")}`
                  }
                >
                  {row.fileName}
                </a>
              </TableCell>
              <TableCell align="right">{df.format(row.date)}</TableCell>
              <TableCell align="right">{pb(row.size)}</TableCell>
              <TableCell align="right">
                {allowDelete && <DeleteIcon onClick={() => deleteFile(row.fileName)}></DeleteIcon>}
                <a href={`${Config.apiEndpoint}/${downloadPrefix}?fileName=` + encodeURIComponent(row.fileName)}>
                  <CloudDownloadIcon className={classes.downloadLink}></CloudDownloadIcon>
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { FileListing };
