import { Config } from "../config";
import { FileDescription } from "../model";

const uploadFile = async (apiToken: string, file: File, name: string): Promise<void> => {
  return (async () => {
    // TODO: Use the shared token for validation, whether this
    //       user can actually upload, and use that to upload
    //       to the correct directory.
    const rawResponse = await fetch(`${Config.apiEndpoint}/getUploadUrl`, {
      method: "POST",
      headers: {
        "x-da-token": apiToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fileName: name, contentType: file.type }),
    });
    const apiResponse = await rawResponse.json();
    if (apiResponse["url"]) {
      // use the url to upload
      await fetch(apiResponse["url"], {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
    } else {
      console.log(`Received unexpected response: ${apiResponse}`);
    }
  })();
};

const getDownloadUrlAndFollowRedirect = async (apiToken: string, file: string): Promise<string> => {
  const p: Promise<string> = (async () => {
    const encodedFilename = encodeURIComponent(file);
    const encodedToken = encodeURIComponent(apiToken);
    const url = `${Config.apiEndpoint}/downloadFromCustomer?fileName=${encodedFilename}&xdatoken=${encodedToken}`;
    const rawResponse = await fetch(url, {
      method: "GET",
      headers: {
        "x-da-token": apiToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (rawResponse.status === 200) {
      const apiResponse = await rawResponse.text();
      return apiResponse;
    } else {
      const apiResponse = "";
      return apiResponse;
    }
  })();
  return p;
};

interface RawResponse {
  fileName: string;
  size: number;
  date: string;
}

const getCustomerListing: (token: string) => Promise<FileDescription[]> = (token) => {
  return (async () => {
    const rawResponse = await fetch(`${Config.apiEndpoint}/getFileListingFromCustomer`, {
      method: "POST",
      headers: {
        "x-da-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const apiResponse = (await rawResponse.json()) as RawResponse[];

    return apiResponse.map((r) => ({
      ...r,
      fileName: r.fileName.replace(token + "/", ""),
      date: new Date(r.date),
    }));
  })();
};

const getDAListing: (token: string) => Promise<FileDescription[]> = (token) => {
  return (async () => {
    const rawResponse = await fetch(`${Config.apiEndpoint}/getFileListingToCustomer`, {
      method: "POST",
      headers: {
        "x-da-token": token,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const apiResponse = (await rawResponse.json()) as RawResponse[];

    return apiResponse.map((r) => ({
      ...r,
      fileName: r.fileName.replace(token + "/", ""),
      date: new Date(r.date),
    }));
  })();
};

const deleteRemoteFile = async (apiToken: string, file: string): Promise<void> => {
  const rawResponse = await fetch(`${Config.apiEndpoint}/deleteFile`, {
    method: "DELETE",
    headers: {
      "x-da-token": apiToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ fileName: file }),
  });

  return undefined;
};

export { uploadFile, getDownloadUrlAndFollowRedirect, getCustomerListing, getDAListing, deleteRemoteFile };
