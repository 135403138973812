import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import * as cs from "crypto-js";
import { tryCatch } from "fp-ts/lib/Either";
import { useUserDispatch } from "../../contexts/UserContext";

/**
 * Properties for the request password dialog
 */
interface RequestPasswordProps {
  open: boolean;
  token: string;
}

/**
 * The request password dialog is shown when the token hasn't been
 * decrypted yet, and hasn't been set into the context
 */
const RequestPassword: React.FC<RequestPasswordProps> = (props) => {
  // keep track of the password and the password errors
  const [password, setPassword] = React.useState<string>("");
  const [passwordError, setPasswordError] = React.useState<boolean>(false);

  // used to have access to the user context
  const userDispatch = useUserDispatch();

  // logins the user when the username and password are correct
  const doLogin = (username: string, apiToken: string) => {
    userDispatch({
      type: "login",
      payload: { name: username, token: apiToken },
    });
  };

  const checkToken = () => {
    console.log(passwordError);
    const checkResult = tryCatch(
      () => cs.AES.decrypt(props.token, password).toString(cs.enc.Utf8),
      (e) => (e instanceof Error ? e : new Error("unknown error"))
    );

    switch (checkResult._tag) {
      case "Left":
        // in case we've got an error. It means the password is incorrect, and we
        // can't even attempt to decode it. This should result in a warning being
        // shown in the login screen.
        setPasswordError(true);
        break;
      case "Right":
        // when the token is correct, and we've got an actual string
        // as result, we can add it to the user store and be automatically
        // routed to the correct page
        if (checkResult.right && checkResult.right.length > 0) {
          console.log(checkResult.right);
          const { name, token } = JSON.parse(checkResult.right) as { name: string; token: string };
          name && token ? doLogin(name, token) : setPasswordError(true);
        } else {
          setPasswordError(true);
        }
        break;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordError(false);
    setPassword(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      checkToken();
    }
  };

  return (
    <div>
      <Dialog disableEscapeKeyDown disableBackdropClick open={props.open} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Voer wachtwoord in</DialogTitle>
        <DialogContent>
          <DialogContentText>Om verder {passwordError} te gaan moet u hier uw wachtwoord invoeren.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Wachtwoord"
            type="password"
            value={password}
            onChange={handleChange}
            error={passwordError}
            helperText={passwordError ? "Ongeldig wachtwoord, probeer opnieuw." : null}
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={checkToken} color="primary">
            Controleer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { RequestPassword };
