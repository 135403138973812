import { Container, createStyles, Divider, makeStyles, Theme, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useUserState } from "../../contexts/UserContext";
import { ClientFileListing } from "./parts/client-file-listing";
import { DAFileListing } from "./parts/da-file-listing";
import { HandigeLinks } from "./parts/handige-links";
import { Remarks } from "./parts/remarks";
import { TopBar } from "./parts/top-bar";
import { UploadFile } from "./parts/upload-file";
import { WaitingSpinner } from "./parts/waiting-spinner";
import { WelcomeText } from "./parts/welcome-text";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      alignContent: "center",
    },
    divider: {
      margin: 4,
      width: "100%",
    },
  })
);

const SecureSharePage: React.FC = () => {
  const classes = useStyles();

  const [showUploading, setShowUploading] = useState<boolean>(false);
  const [daListingLastUpdate, setDaListingLastUpdate] = useState<Date>(new Date());
  const user = useUserState();

  const uploadCompleted = () => {
    // asyncGetListing();
    setDaListingLastUpdate(new Date());
  };

  return (
    <div style={{ width: "100%" }}>
      <TopBar></TopBar>
      <Container className={classes.root}>
        <WelcomeText />
        <Divider className={classes.divider} />
        <UploadFile uploadState={setShowUploading} onUploadCompleted={uploadCompleted} />
        <Divider className={classes.divider} />
        <Remarks />
        <Divider className={classes.divider} />
        <ClientFileListing key={daListingLastUpdate.getTime()} />
        <Divider className={classes.divider} />
        <DAFileListing />
        <Divider className={classes.divider} />
        <HandigeLinks />
        <Divider className={classes.divider} />
      </Container>
      <WaitingSpinner showSpinner={showUploading}></WaitingSpinner>
    </div>
  );
};

export { SecureSharePage };
