import React from "react";
import { FileDescription } from "../../../model";
import { FileListing } from "./file-listing";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useUserState } from "../../../contexts/UserContext";
import { getDAListing } from "../../../util/fileserver-client";

interface DAFileListingProps {}

const DAFileListing: React.FC<DAFileListingProps> = () => {
  const user = useUserState();
  const [daUploadedFiles, setDaUploadedFiles] = React.useState<FileDescription[]>([]);
  const asyncGetListing = async () => {
    const foundFiles = await getDAListing(user.user?.token ?? "");
    setDaUploadedFiles(foundFiles);
  };

  React.useEffect(() => {
    asyncGetListing();
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6">Bestanden die wij voor u klaargezet hebben:</Typography>
      <Typography variant="body1">Hieronder vindt u bestanden die wij met u willen delen.</Typography>

      <FileListing
        filesToShow={daUploadedFiles}
        allowDelete={false}
        downloadPrefix={"downloadToCustomer"}
      ></FileListing>
    </React.Fragment>
  );
};

export { DAFileListing };
