import React from "react";
import { FileDescription } from "../../../model";
import { FileListing } from "./file-listing";
import Alert from "@material-ui/lab/Alert";
import { Snackbar, Typography } from "@material-ui/core";
import { getCustomerListing } from "../../../util/fileserver-client";
import { useUserState } from "../../../contexts/UserContext";

interface ClientFileListingProps {}

interface SnackbarMessage {
  message: string;
  key: number;
}

const ClientFileListing: React.FC<ClientFileListingProps> = () => {
  const onFileDeleted = (fileName: string) => {
    addSnackBarMessage(fileName);
    asyncGetListing();
  };

  const [snackPack, setSnackPack] = React.useState<SnackbarMessage[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);
  const user = useUserState();

  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const addSnackBarMessage = (message: string) => {
    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  };

  const handleClose = (event: React.SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const [clientUploadedFile, setClientUploadedFiles] = React.useState<FileDescription[]>([]);

  const asyncGetListing = async () => {
    const foundFiles = await (await getCustomerListing(user.user?.token ?? "")).filter(
      (f) => f.fileName !== "remarks.txt"
    );
    setClientUploadedFiles(foundFiles);
  };

  React.useEffect(() => {
    asyncGetListing();
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6">Uw geüploade bestanden:</Typography>
      <Typography variant="body1">Hieronder ziet u de bestanden die u met ons deelt.</Typography>

      <FileListing
        filesToShow={clientUploadedFile}
        allowDelete={true}
        onFileDeleted={onFileDeleted}
        downloadPrefix={"downloadFromCustomer"}
      ></FileListing>

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        onExited={handleExited}
      >
        <Alert severity="info">Bestand {messageInfo?.message} verwijderd.</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export { ClientFileListing };
