import React, { useEffect, useLayoutEffect, useState } from "react";
import { Switch, BrowserRouter, Route, useParams, useLocation } from "react-router-dom";
import { UserProvider, useUserDispatch, useUserState } from "./contexts/UserContext";
import { SecureSharePage } from "./pages/secure-share/secure-share";
import base64url from "base64url";
import { RequestPassword } from "./pages/request-password/request-password";

/**
 * The route to use when we're not authenticated
 */
const UnauthenticatedAppRoutes: React.FC<{ token: string }> = ({ token }) => {
  return (
    <React.Fragment>
      <Route path="/" component={() => <RequestPassword open token={token}></RequestPassword>} />
    </React.Fragment>
  );
};

/**
 * The route to use when we're authenticated
 */
const AuthenticatedAppRoutes: React.FC = () => {
  return (
    <React.Fragment>
      <Route path="/" component={SecureSharePage} />
    </React.Fragment>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AllRoutes: React.FC = () => {
  const authAccount = useUserState();
  const userDispatch = useUserDispatch();
  const query = useQuery();

  const [token, setToken] = useState<string>("");

  // only run this once, since when we load the app from a new URL, it'll
  // need to recheck the token again. As long as we're not refreshing the
  // page, or something else, we only need to run this once.
  useLayoutEffect(() => {
    const queryToken = query.get("token");
    if (queryToken) {
      const tokenDecoded = base64url.decode(queryToken);
      // set the token in the state, and also store it in localstorage.
      setToken(tokenDecoded);
      // we do this so when coming back to the page we can check whether
      // the token is changed when we try and login again based on the
      // stored information
      if (queryToken !== localStorage.getItem("da_token")) {
        // the query token has changed, clean the other stored data to
        // force a reload
        localStorage.removeItem("da_user");
      }
      localStorage.setItem("da_token", queryToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // if the token is empty, redirect to error page, since we can't continue without a token
  if (token === "") {
    return <div>No token present. Check link</div>;
  } else {
    return (
      <Switch>{authAccount.loggedIn ? <AuthenticatedAppRoutes /> : <UnauthenticatedAppRoutes token={token} />}</Switch>
    );
  }
};

/**
 * Main structure of the application routes
 */
const AppRoutes: React.FC = () => {
  return (
    <UserProvider>
      <BrowserRouter>
        <AllRoutes></AllRoutes>
      </BrowserRouter>
    </UserProvider>
  );
};

export { AppRoutes };
