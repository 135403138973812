import { Box, Button, makeStyles, Snackbar, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useUserState } from "../../../contexts/UserContext";
import { getDownloadUrlAndFollowRedirect, uploadFile } from "../../../util/fileserver-client";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  textAreaContainer: {
    paddingTop: "12px",
    paddingBottom: "12px",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
  },
  textArea: {
    width: "100%",
    paddingBottom: "5px",
  },
});

const Remarks: React.FC = () => {
  const styles = useStyles();

  const user = useUserState();
  const [initialText, setInitialText] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [saveEnabled, setSaveEnabled] = useState<boolean>(false);

  const [remarksSavedSnackBar, setRemarksSavedSnackBar] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    setSaveEnabled(event.target.value !== initialText);
  };

  const handleClose = () => {
    setRemarksSavedSnackBar(false);
  };

  const saveRemarks = () => {
    const remarksFile = new File([text], "remarks.txt");
    uploadFile(user.user?.token ?? "", remarksFile, "remarks.txt").then(() => {
      setInitialText(text);
      setSaveEnabled(false);
      setRemarksSavedSnackBar(true);
    });
  };

  /**
   * When mounted load the latest data
   */
  useEffect(() => {
    console.log("Mounted");
    getDownloadUrlAndFollowRedirect(user.user?.token ?? "", "remarks.txt")
      .then((res) => {
        setInitialText(res);
        setText(res);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6">Vragen of opmerkingen:</Typography>
      <Box className={styles.root}>
        <Typography variant="body1">
          Heeft u nog vragen of opmerkingen dan kunt u deze hieronder invoeren. Zodra u op opslaan klikt, dan kan
          Dirksen Administratie en Advies uw opmerkingen lezen.
        </Typography>

        <Box className={styles.textAreaContainer}>
          <TextField
            className={styles.textArea}
            id="outlined-multiline-static"
            label="Opmerkingen en vragen"
            multiline
            rows={4}
            defaultValue={text}
            value={text}
            variant="outlined"
            onChange={handleChange}
          />
          <Button variant="contained" color="primary" disabled={!saveEnabled} onClick={saveRemarks}>
            Opslaan
          </Button>
        </Box>
      </Box>
      <Snackbar open={remarksSavedSnackBar} autoHideDuration={2000} onClose={handleClose} onExited={handleClose}>
        <Alert severity="info">Uw opmerkingen zijn opgeslagen.</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export { Remarks };
