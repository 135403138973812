import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { useUserState } from "../../../contexts/UserContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 2,
    },
  })
);

const HandigeLinks: React.FC = () => {
  const classes = useStyles();

  const user = useUserState();

  return (
    <div className={classes.root}>
      <Typography variant="h6">Handige links:</Typography>
      <Typography variant="body1">
        <p>
          Financieel jaaroverzichten downloaden:
          <ul>
            <li>
              <a href="https://www.rabobank.nl/particulieren/betalen/service/financiele-overzichten/financieel-jaaroverzicht-ontvangen/">
                Rabobank
              </a>
            </li>
            <li>
              <a href="https://www.ing.nl/particulier/internetbankieren/internetbankieren/mijn-ing/jaaroverzicht/index.html">
                ING
              </a>
            </li>

            <li>
              <a href="https://www.regiobank.nl/service/algemeen/jaaroverzichten.html">Regiobank</a>
            </li>
            <li>
              <a href="https://www.snsbank.nl/particulier/service/jaaroverzichten.html">SNS</a>
            </li>
            <li>
              <a href="https://www.abnamro.nl/nl/prive/betalen/financieel-jaaroverzicht/index.html">ABN Amro</a>
            </li>
          </ul>
        </p>
      </Typography>
    </div>
  );
};

export { HandigeLinks };
