import * as React from "react";
import { User } from "../model";
import base64url from "base64url";

type Action = { type: "login"; payload: User } | { type: "logout" };
type Dispatch = (action: Action) => void;
type State = { user?: User; loggedIn: boolean };
type UserProviderProps = { children: React.ReactNode };

// We provide two contexts users can use. Either the state when they only
// need to listen to updates, or the dispatch, if they need to change the
// state.
const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(undefined);

/**
 * Reduces the actions and the state, and returns a new state
 *
 * @param state the current state
 * @param action the action to take on the state
 * @returns The new user state
 */
function userAuthReducer(state: State, action: Action): State {
  switch (action.type) {
    case "login": {
      // on login set the user and the token
      localStorage.setItem("da_user", base64url.encode(JSON.stringify(action.payload)));
      return {
        user: { name: action.payload.name, token: action.payload.token },
        loggedIn: true,
      };
    }
    case "logout": {
      localStorage.removeItem("da_user");
      return { user: undefined, loggedIn: false };
    }
  }
}

/**
 * A simple wrapper provider, which provides the user context to the child components
 */
function UserProvider({ children }: UserProviderProps): JSX.Element {
  const [state, dispatch] = React.useReducer(userAuthReducer, { user: undefined, loggedIn: false });

  React.useEffect(() => {
    const foundInLocalStorage = localStorage.getItem("da_user");
    if (foundInLocalStorage) {
      const user = JSON.parse(base64url.decode(foundInLocalStorage)) as User;
      dispatch({ type: "login", payload: user });
    }
  }, []);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

/**
 * Provides access to the user stateContext
 */
function useUserState(): State {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}

/**
 * Provides access to the user stateDispatchContext
 */
function useUserDispatch(): Dispatch {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}

// set of exports to make this usuable
export { UserProvider, useUserState, useUserDispatch };
