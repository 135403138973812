import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useUserDispatch } from "../../../contexts/UserContext";

/**
 * Configure the styles for this component.
 */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "40px",
      maxHeight: "40px",
    },
    menuTop: {
      marginRight: theme.spacing(0),
    },
    appBar: {
      height: "40px",
      maxHeight: "40px",
    },
    toolBar: {
      height: "40px",
      maxHeight: "40px",
      minHeight: "40px",
    },
    title: {
      flexGrow: 1,
    },
  })
);

const TopBar: React.FC = () => {
  const classes = useStyles();

  // used to have access to the user context
  const userDispatch = useUserDispatch();

  const logout = () => {
    userDispatch({
      type: "logout",
    });
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="body1" className={classes.title}>
            Bestanden delen - Dirksen Administratie en Advies
          </Typography>
          <Button color="inherit" onClick={logout}>
            Uitloggen
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { TopBar };
