import { Button, ButtonGroup, createStyles, makeStyles, Snackbar, Typography } from "@material-ui/core";
import Dropzone, { FileRejection, useDropzone } from "react-dropzone";
import React, { RefObject, useState } from "react";
import { uploadFile } from "../../../util/fileserver-client";
import Resizer from "react-image-file-resizer";
import { useUserState } from "../../../contexts/UserContext";
import moment from "moment";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    dropzone: {
      textAlign: "center",
      padding: "20px",
      border: "3px dashed #eeeeee",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      paddingTop: "40px",
      paddingBottom: "40px",
    },
  })
);

interface UploadFileProps {
  uploadState: (isUploading: boolean) => void;
  onUploadCompleted: () => void;
}

const UploadFile: React.FC<UploadFileProps> = ({ uploadState, onUploadCompleted }) => {
  const classes = useStyles();
  const user = useUserState();
  const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  const [remarksSavedSnackBar, setRemarksSavedSnackBar] = useState<boolean>(false);
  const handleClose = () => {
    setRemarksSavedSnackBar(false);
  };

  const handleAcceptedFiles = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (acceptedFiles.length > 0 && fileRejections.length === 0) {
      // we can start the upload
      uploadState(true);

      // when we get a set of files, we need to check
      // whether the names match a uuid or image.jpeg. Since
      // that are the names used by iOS
      const setOfPromises = acceptedFiles.map((f, index) => {
        const extension = f.name.split(".").pop() ?? "";
        const name = f.name.substring(0, f.name.length - (extension.length !== 0 ? extension.length + 1 : 0));

        if (regex.test(name.toLowerCase())) {
          // we're going to rename it to date-time stamp
          const newFileName = moment(new Date()).format(`YYYY-MM-DD-HH-mm-ss-${index}`);
          return uploadFile(user.user?.token ?? "", f, `${newFileName}.${extension}`);
        } else if (name === "image") {
          const newFileName = moment(new Date()).format(`YYYY-MM-DD-HH-mm-ss-${index}`);
          return uploadFile(user.user?.token ?? "", f, `${newFileName}.${extension}`);
        } else {
          return uploadFile(user.user?.token ?? "", f, f.name);
        }
      });

      Promise.all(setOfPromises).then((res) => {
        uploadState(false);
        onUploadCompleted();
        setRemarksSavedSnackBar(true);
      });
    } else {
      // we should log a warning as a popup thingy at the bottom
    }
    return undefined;
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h6">Verstuur bestand(en):</Typography>
        <Typography variant="body1">
          Hieronder kunt u uw bestanden (maximaal 5 tegelijk) met ons delen. Dit kan op de volgende manieren:
          <ul>
            <li>Sleep uw bestanden in het vak;</li>
            <li>Of klik hieronder om een bestand te selecteren;</li>
            <li>Of klik om een foto te maken en toe te voegen (vanaf tablet of telefoon).</li>
          </ul>
        </Typography>
        <Dropzone onDrop={handleAcceptedFiles} maxFiles={5}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: classes.dropzone })}>
                <input {...getInputProps()} />
                <div>
                  <Typography variant="body1">Sleep bestanden of klik hier...</Typography>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <Snackbar open={remarksSavedSnackBar} autoHideDuration={2000} onClose={handleClose} onExited={handleClose}>
        <Alert severity="info">Uw bestanden zijn opgeslagen.</Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export { UploadFile };
